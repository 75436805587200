<template>
    <section class="ImageModalGrid" :id="sliceId">
        <h2 v-if="slice.primary.section_title" class="ImageModalGrid__title">
            <FillTextEffect
                :id="sliceId"
                :trigger-container="'#' + sliceId"
                bottom-position="500px"
                :text="slice.primary.section_title"
            />
        </h2>
        <BullImageModalGrid :items="transformedItems" />
    </section>
</template>

<script setup lang="ts">
import { uid } from 'uid'
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.ImageModalGridSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const sliceId = props.slice.id.replace('$', '-')

const transformedItems = computed(() => {
    return props.slice.items.reduce((previousValue, item) => {
        previousValue.push({
            id: uid(),
            imageUrl: item.image.url,
            imageDimensions: [item.image.dimensions],
            title: item.title,
            subtitle: item.subtitle,
            description: item.modal_content,
        })

        return previousValue
    }, [])
})
</script>

<style lang="scss">
.ImageModalGrid {
    grid-column: 2 / span 22;
    padding-top: 8rem;

    @include large-up {
        padding-top: 11.25rem;
    }

    @media (min-width: 1280px) {
        grid-column: 3 / span 20;
    }

    @include xl-up {
        grid-column: 4 / span 18;
    }
}

.ImageModalGrid__title {
    @include heading-2;

    max-width: 52rem;
    margin-bottom: 3rem;

    @include medium-up {
        margin-bottom: 5rem;
    }
}
</style>
