<template>
    <div
        class="BullImageModalGrid"
        :class="[transitionReady ? 'BullImageModalGrid--active' : '']"
    >
        <ul class="BullImageModalGrid__list list-unstyled">
            <li
                v-for="item in items"
                :key="item.id"
                class="BullImageModalGrid__list-item"
            >
                <button
                    class="BullImageModalGrid__button"
                    @click.native="openModal($event, item.id)"
                >
                    <div
                        class="BullImageModalGrid__image-wrapper"
                        :data-active-wrapper="activeItem.id === item.id"
                        :style="{
                            aspectRatio: `auto ${
                                item.imageDimensions.length > 0 &&
                                item.imageDimensions[0]
                                    ? item.imageDimensions[0].width
                                    : 224
                            } / ${
                                item.imageDimensions.length > 0 &&
                                item.imageDimensions[0]
                                    ? item.imageDimensions[0].height
                                    : 299
                            }`,
                        }"
                    >
                        <SmartImage
                            :url="item.imageUrl"
                            :srcs="item.imageDimensions"
                            :width="
                                item.imageDimensions.length > 0 &&
                                item.imageDimensions[0]
                                    ? item.imageDimensions[0].width
                                    : 224
                            "
                            :height="
                                item.imageDimensions.length > 0 &&
                                item.imageDimensions[0]
                                    ? item.imageDimensions[0].height
                                    : 299
                            "
                            :as-bg="true"
                            positioning="relative"
                            @mouseover="
                                cursorStore.setCursorText('View<br/>more')
                            "
                            @mouseleave="cursorStore.setCursorText('')"
                            class="BullImageModalGrid__image"
                            :class="[
                                activeItem.id === item.id
                                    ? 'BullImageModalGrid__image--active'
                                    : '',
                                activeItem.id === item.id && transitionEnded
                                    ? 'BullImageModalGrid__image--hidden'
                                    : '',
                            ]"
                        />
                        <div class="BullImageModalGrid__expand-icon">
                            <IconButton
                                icon="fullscreen"
                                filled="white"
                                size="x-small"
                                element="div"
                            />
                        </div>
                    </div>
                    <h3 v-if="item.title" class="BullImageModalGrid__title">
                        {{ item.title }}
                    </h3>
                    <p
                        v-if="item.subtitle"
                        class="BullImageModalGrid__subtitle"
                    >
                        {{ item.subtitle }}
                    </p>
                </button>
            </li>
        </ul>
        <Teleport to="#teleport-container">
            <div
                :class="[
                    transitionReady ? 'BullImageModalGrid--active' : '',
                    isClosing ? 'BullImageModalGrid--closing' : '',
                ]"
            >
                <div class="BullImageModalGrid__modal">
                    <div class="BullImageModalGrid__modal-bg"></div>
                    <div class="BullImageModalGrid__modal-header">
                        <div class="BullImageModalGrid__modal-button-wrapper">
                            <button
                                class="BullImageModalGrid__modal-button"
                                @click.native="closeModal"
                                @mouseover="
                                    cursorStore.setCursorType('link-hover')
                                "
                                @mouseleave="
                                    cursorStore.setCursorType('default')
                                "
                            >
                                <FontIcon
                                    icon="close"
                                    color="white"
                                    size="stretch"
                                />
                            </button>
                        </div>
                    </div>
                    <div class="BullImageModalGrid__modal-grid-wrapper">
                        <div class="BullImageModalGrid__modal-wrapper">
                            <div
                                class="BullImageModalGrid__modal-content"
                                ref="modalContent"
                                :style="modalContentStyles"
                            >
                                <h3
                                    v-if="activeItem.title"
                                    class="BullImageModalGrid__modal-content-title"
                                >
                                    {{ activeItem.title }}
                                </h3>
                                <p
                                    v-if="activeItem.subtitle"
                                    class="BullImageModalGrid__modal-content-subtitle"
                                >
                                    {{ activeItem.subtitle }}
                                </p>
                                <PrismicRichText
                                    v-if="activeItem.description"
                                    :field="activeItem.description"
                                    class="BullImageModalGrid__modal-content-desc"
                                />
                            </div>
                            <div
                                :style="imageTransitionaryStyles"
                                ref="modalImageWrapper"
                                class="BullImageModalGrid__modal-placeholder-image"
                            >
                                <button
                                    @click.native="closeModal"
                                    ref="modalImage"
                                    class="BullImageModalGrid__button"
                                    @mouseover="
                                        cursorStore.setCursorType('close')
                                    "
                                    @mouseleave="
                                        cursorStore.setCursorType('default')
                                    "
                                >
                                    <SmartImage
                                        :url="activeItem.imageUrl"
                                        :srcs="activeItem.imageDimensions"
                                        :width="
                                            activeItem?.imageDimensions
                                                ?.length > 0 &&
                                            activeItem.imageDimensions[0]
                                                ? activeItem.imageDimensions[0]
                                                      .width
                                                : 561
                                        "
                                        :height="
                                            activeItem?.imageDimensions
                                                ?.length > 0 &&
                                            activeItem.imageDimensions[0]
                                                ? activeItem.imageDimensions[0]
                                                      .height
                                                : 748
                                        "
                                        positioning="relative"
                                        ref="modalImage"
                                        :with-placeholder="true"
                                        :class="
                                            transitionEnded
                                                ? 'BullImageModalGrid__modal-image--show'
                                                : ''
                                        "
                                        :as-bg="true"
                                        class="BullImageModalGrid__modal-image"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => [],
            required: true,
        },
    },

    setup() {
        const cursorStore = useCursorStore()

        return { cursorStore }
    },

    data() {
        return {
            activeItem: {},
            transitionReady: false,
            modalImageStartStyles: {},
            imageTransitionaryStyles: {},
            transitionEnded: false,
            modalContentStyles: {},
            isClosing: false,
        }
    },

    computed: {
        hasActiveItem() {
            return Object.keys(this.activeItem).length > 0
        },
    },

    methods: {
        openModal(e, id) {
            this.activeItem = this.items.filter((item) => item.id === id)[0]

            const gridImagePos = e.target.getBoundingClientRect()
            const modalImagePos = this.$refs.modalImage.getBoundingClientRect()

            this.modalImageStartStyles = {
                width: modalImagePos.width + 'px',
                height: modalImagePos.height + 'px',
                top: modalImagePos.top + 'px',
                left: modalImagePos.left + 'px',
                position: 'fixed',
            }

            this.imageTransitionaryStyles = {
                width: gridImagePos.width + 'px',
                height: gridImagePos.height + 'px',
                top: gridImagePos.top + 'px',
                left: gridImagePos.left + 'px',
                position: 'absolute',
            }

            setTimeout(() => {
                this.transitionReady = true

                this.$refs.modalImageWrapper.addEventListener(
                    'transitionend',
                    () => {
                        this.transitionEnded = true
                        this.modalContentStyles = {}
                        this.imageTransitionaryStyles = {}
                    },
                    { once: true },
                )

                this.imageTransitionaryStyles = this.modalImageStartStyles
            }, 100)
        },
        closeModal() {
            const modalImagePos = this.$refs.modalImage.getBoundingClientRect()

            const modalContentPos =
                this.$refs.modalContent.getBoundingClientRect()

            this.imageTransitionaryStyles = {
                width: modalImagePos.width + 'px',
                height: modalImagePos.height + 'px',
                top: modalImagePos.top + 'px',
                left: modalImagePos.left + 'px',
                position: 'fixed',
                transition: 'none',
            }

            this.modalContentStyles = {
                width: modalContentPos.width + 'px',
                height: modalContentPos.height + 'px',
                top: modalContentPos.top + 'px',
                left: modalContentPos.left + 'px',
                position: 'fixed',
                marginTop: 0 + 'px',
            }

            setTimeout(() => {
                setTimeout(() => {
                    const activeWrapper = document
                        .querySelector('[data-active-wrapper="true"]')
                        .getBoundingClientRect()

                    this.imageTransitionaryStyles = {
                        width: activeWrapper.width + 'px',
                        height: activeWrapper.height + 'px',
                        top: activeWrapper.top + 'px',
                        left: activeWrapper.left + 'px',
                        position: 'fixed',
                    }

                    setTimeout(() => {
                        this.isClosing = true

                        this.$refs.modalImageWrapper.addEventListener(
                            'transitionend',
                            () => {
                                this.isClosing = false
                                this.imageTransitionaryStyles = {}
                                this.activeItem = {}
                                this.transitionEnded = false
                                this.modalContentStyles = {}
                                this.modalImageStartStyles = {}
                            },
                            { once: true },
                        )

                        setTimeout(() => {
                            this.transitionReady = false
                        }, 0)
                    }, 100)
                }, 100)
            }, 100)
        },
    },

    watch: {
        hasActiveItem(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        },
    },
}
</script>

<style lang="scss">
.BullImageModalGrid {
    display: grid;
}

.BullImageModalGrid--active,
.BullImageModalGrid--closing {
    .BullImageModalGrid__modal-placeholder-image {
        transition: all 0.6s 0s ease;
    }
}

.BullImageModalGrid--active {
    .BullImageModalGrid__modal {
        z-index: 9998;
        pointer-events: all;
        overflow: auto;
    }

    .BullImageModalGrid__image {
        transition: all 0.6s 0s ease;
    }

    .BullImageModalGrid__modal-image {
        opacity: 1;
    }

    .BullImageModalGrid__modal-button {
        opacity: 1;
        transform: rotate(90deg);
        transition: all 0.6s 0.3s ease;
        transition-property: opacity, transform;
        pointer-events: all;
    }

    .BullImageModalGrid__modal-bg {
        opacity: 1;
    }

    .BullImageModalGrid__modal-content {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.BullImageModalGrid__list {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 50px;
    row-gap: 3rem;

    @include medium-only {
        grid-template-columns: 1fr 1fr;
    }

    @include large-up {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 5rem;
    }
}

.BullImageModalGrid__button {
    width: 100%;
    height: 100%;
    transition: all 0.6s 0s ease;
    color: var(--white);
    text-align: left;
}

.BullImageModalGrid__title {
    @include heading-3;

    margin-top: 1.5rem;
}

.BullImageModalGrid__subtitle {
    @include body-lg;

    margin-top: 0.5rem;
}

.BullImageModalGrid__image {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateZ(0); // fixes safari issue not rendering borders
}

.BullImageModalGrid__image-wrapper {
    width: 100%;
    position: relative;
}

.BullImageModalGrid__image--active {
    z-index: 9999;
}

.BullImageModalGrid__image--hidden {
    display: none;
}

.BullImageModalGrid__modal {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    pointer-events: none;
}

.BullImageModalGrid__modal-bg {
    position: fixed;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    transition: opacity 0.6s 0s ease;
}

.BullImageModalGrid__modal-header {
    @include grid;

    padding-top: 34px;
    padding-bottom: 32px;
}

.BullImageModalGrid__modal-grid-wrapper {
    @include grid;

    width: 100%;
    position: relative;
    color: var(--white);
    margin-bottom: 90px;

    @include medium-up {
        height: calc(100% - 90px);
    }
}

.BullImageModalGrid__modal-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column: 2 / span 22;
    column-gap: 100px;
    height: 100%;

    @include small-only {
        display: flex;
        flex-direction: column;
    }
}

.BullImageModalGrid__modal-image {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.BullImageModalGrid__modal-image--show {
    opacity: 1;
}

.BullImageModalGrid__expand-icon {
    display: none;
    position: absolute;
    bottom: 20px;
    right: 20px;
    pointer-events: none;

    @include touchscreen {
        display: block;
    }
}

.BullImageModalGrid__modal-image,
.BullImageModalGrid__image {
    overflow: hidden;
}

.BullImageModalGrid__modal-placeholder-image {
    height: 50%;
    transition: none;

    @include small-only {
        margin-bottom: 32px;
        order: 1;
        height: 75%;
    }

    @include medium-only {
        height: 100%;
        width: 200%;
    }

    @include medium-up {
        align-self: center;
    }

    @include large-up {
        height: 80%;
    }
}

.BullImageModalGrid__modal-content {
    transform: translate3d(0, 32px, 0);
    opacity: 0;
    transition:
        transform 0.6s 0s ease,
        opacity 0.6s 0s ease;
    align-self: center;
    max-width: 536px;

    @include small-only {
        order: 2;
        align-self: flex-start;
    }

    @include medium-up {
        margin-top: -90px;
    }
}

.BullImageModalGrid__modal-button-wrapper {
    grid-column: 22 / span 2;

    @include medium-up {
        grid-column: 23;
    }
}

.BullImageModalGrid__modal-button {
    opacity: 0;
    transition: opacity 0.6s 0s ease;
}

.BullImageModalGrid__modal-content-subtitle {
    @include body-lg;
}

.BullImageModalGrid__modal-content-title {
    @include heading-2;
}

.BullImageModalGrid__modal-content-desc {
    margin-top: 16px;

    @include medium-up {
        margin-top: 32px;
    }

    p {
        margin-bottom: 1rem;
    }

    a {
        color: var(--lime-green);
    }
}
</style>
